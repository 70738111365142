import Moment from "moment";

export const calculateTimeDifference = (
	time1,
	time2,
	measurement = "hours"
) => {
	if (!time1 || !time2) {
		return null;
	}
	try {
		const firstTime = Moment(time1);
		const secondTime = Moment(time2);
		const difference = firstTime.diff(secondTime, measurement);
		if (difference < 0) {
			return difference * -1;
		}
		if (isNaN(difference)) {
			return null;
		}
		return difference;
	} catch (error) {
		console.error(error);
		return null;
	}
};
