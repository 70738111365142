import { useEffect, useState } from "react";
import { DataTable, Grid, GridCol } from "@flixbus/honeycomb-react";
//HC components
import { Button, Dropdown } from "@flixbus/honeycomb-react";
// components
import { SectionHeader } from "../../SectionHeader/SectionHeader";
import { PaymentTableRow } from "../../../components";
import { ActionPopup } from "../../../../../components";
import AccessGuard from "../../../../../auth/AccessGuard";
// constants
import * as CONSTANTS from "../../../../../constants";
import { USER_ROLES } from "../../../../../auth/UserRoles";
// styles
import "./PaymentBasicInfo.scss";
// utils
import {
	calculateTimeDifference,
	findRefundablePayment,
} from "../../../../../utils";

export const PaymentBasicInfo = ({ order }) => {
	const { payments } = order;
	const [isWarningShown, setIsWarningShown] = useState(false);
	const [isRefundPossible, setIsRefundPossible] = useState(false);
	const [refundablePayment, setRefundablePayment] = useState(null);

	const proceed = () => {
		setIsWarningShown(false);
		window.open(`/orders/${order.id}/refund`, "_blank");
	};

	const checkPaymentTime = () => {
		// check if the payment was done earlier than 24 hours, if yes show warning
		const timeDifference = calculateTimeDifference(
			new Date(),
			refundablePayment?.createdAt || null,
			"hours"
		);
		if (timeDifference < 24) {
			setIsWarningShown(true);
		} else {
			// if >= 24h: proceed with refund
			proceed();
		}
	};

	// options for the payments dropdown
	const PaymentsActionsArray = [
		{
			text: "PSP Refund",
			Elem: "button",
			onClick: checkPaymentTime,
		},
	];

	useEffect(() => {
		setRefundablePayment(null);
		setIsRefundPossible(false);
		const refundablePayment = findRefundablePayment(order);
		setRefundablePayment(refundablePayment);
		if (refundablePayment) {
			setIsRefundPossible(!!refundablePayment);
		}
	}, [order]);

	return payments && payments.length ? (
		<>
			{/* header */}
			<Grid>
				<GridCol>
					<SectionHeader
						type="payment"
						headerText="Payments"
						orderId={order.id}
					/>
				</GridCol>
				{isRefundPossible ? (
					<GridCol extraClasses="PaymentsDropdown">
						<AccessGuard
							allowedAzureGroups={[USER_ROLES.REFUND_USER]}
							showMessage={false}
						>
							<Dropdown
								links={PaymentsActionsArray}
								xPosition="left"
								data-testid="payments-options-button"
							>
								<Button
									display="square"
									aria-label="Open Payment Actions"
									extraClasses="PaymentsDropdown__additionalMenu"
								>
									<span className="Dots">...</span>
								</Button>
							</Dropdown>
						</AccessGuard>
					</GridCol>
				) : null}
			</Grid>

			<DataTable
				headers={CONSTANTS.PaymentHeaders}
				scrollable
				extraClasses="SectionTable"
				data-testid="payments-table"
			>
				{payments.map((payment, i) => (
					<PaymentTableRow
						key={i}
						payment={payment}
						currency={order.currency}
						data-testid="payment-row"
					/>
				))}
			</DataTable>
			{/* action popup with time warning */}
			{isWarningShown ? (
				<ActionPopup
					title="Warning"
					text={CONSTANTS.RefundWarningMessage}
					onAction={() => proceed()}
					actionButtonText="Proceed"
					isShown={true}
					onClose={() => setIsWarningShown(false)}
				/>
			) : null}
		</>
	) : null;
};
