export const findRefundablePayment = (order) => {
	try {
		const { payments } = order;
		if (payments && payments.length) {
			// filter payments with pspReference and amount > 0
			const filteredPayments = payments.filter(
				(payment) => !!payment.pspReference && parseFloat(payment.amount) !== 0
			);
			// when multiple non-0 payments are found:
			if (filteredPayments.length > 1) {
				// need to compare statuses and select the one with the PAID status
				// TODO: add more statuses to the comparison when it's clarified
				const paidPayment = filteredPayments.find(
					(payment) => payment.status === "paid"
				);
				return paidPayment || null;
			}
			// if there is 1 payment - it's refundable
			return filteredPayments.length > 0 ? filteredPayments[0] : null;
		} else {
			// if there are no payments
			return null;
		}
	} catch (error) {
		console.error("Error in findRefundablePayment: ", error);
		return null;
	}
};
