import React, { useRef } from "react";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import { useIsAuthenticated } from "@azure/msal-react";
// components
import {
	Button,
	Checkbox,
	Grid,
	GridCol,
	Heading,
	Input,
	PageContainer,
	Select,
	SelectOption,
	Spinner,
	Fieldset,
	Radio,
} from "@flixbus/honeycomb-react";
import { NoResultsMessage, Price } from "../../components";
import RefundItemsTable from "./components/RefundItemsTable/RefundItemsTable";
import { RefundOrderDetails } from "./components/RefundOrderDetails/RefundOrderDetails";
import RefundNotification from "./components/RefundNotification/RefundNotification";
// constants
import { OrdersMockup } from "../../constants";
import * as CONSTANTS from "../../constants";
// utils
import {
	authenticate,
	buildRefundCommentBody,
	buildRefundRequestBody,
	createUserData,
	findRefundablePayment,
	isLocalhost,
	validateRefundRequest,
} from "../../utils";
import { useErrorStatus } from "../../utils/hooks";
import AccessGuard from "../../auth/AccessGuard";
// services
import { getOrderDetails } from "../orders/Orders.service";
import { loginRequest } from "../../authConfig";
import { sendRefundRequest } from "./Refund.service";
import { addComment } from "../../api";
// styles
import "./Refund.scss";
import { RefundAmountError } from "./components/RefundAmountError/RefundAmountError";
import RequestSuccess from "./components/RequestSuccess/RequestSuccess";
import { RequestError } from "./components/RequestError/RequestError";

const Refund = () => {
	// order id is passed as a param in the url
	const { id: orderId } = useParams();
	const isAuthenticated = useIsAuthenticated();
	const { instance, accounts } = useMsal();
	const [token, setToken] = useState(null);
	const request = {
		...loginRequest,
		account: accounts[0],
	};
	const [user, setUser] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [isNotFound, setIsNotFound] = useState(false);
	const [order, setOrder] = useState({});
	const [payment, setPayment] = useState({});
	const [actualPayments, setActualPayments] = useState([]);
	const [buttonAppearance, setButtonAppearance] = useState("");
	const inputRefundConfirmed = useRef(null);
	// custom amount
	const [isCustomRefund, setIsCustomRefund] = useState(false);
	const inputCustomRefund = useRef(null);
	const [customAmount, setCustomAmount] = useState(0);
	// selected items
	const [itemsAmount, setItemsAmount] = useState(0);
	const [selectedItems, setSelectedItems] = useState([]);
	const [refundReason, setRefundReason] = useState(null);
	const [exceedsMaxRefund, setExceedsMaxRefund] = useState(false);
	// validation states
	const [isFormValid, setIsFormValid] = useState(true);
	const [isValidCustomAmount, setIsValidCustomAmount] = useState(true);
	const [isValidItemsAmount, setIsValidItemsAmount] = useState(true);
	const [isValidRefundReason, setIsValidRefundReason] = useState(true);
	// final refund state
	const [isRequestLoading, setIsRequestLoading] = useState(false);
	const [refundAmount, setRefundAmount] = useState(0);
	const [isRefundTriggered, setIsRefundTriggered] = useState(false);
	const [isSuccess, setIsSuccess] = useState(false);
	const [errorObj, setErrorObj] = useState(null);
	const [showNotification, setShowNotification] = useState(false);
	const [errorStatus, setErrorStatus] = useState(null);

	useErrorStatus(errorStatus);

	const getOrder = (token, orderId) => {
		// needed for logs
		const userInfo = createUserData(accounts[0]);
		setUser(userInfo);
		getOrderDetails(userInfo, token, orderId).then((response) => {
			if (response && response.data) {
				setOrder(response.data);
				setIsNotFound(false);
			} else {
				setIsNotFound(true);
			}
			setIsLoading(false);
		});
	};

	useEffect(() => {
		// Set the tab title dynamically
		document.title = `Refund ${orderId}`;
		// Clean up by resetting the tab title when component unmounts
		return () => {
			document.title = "BirdView";
		};
	}, [orderId]);

	// get token
	useEffect(() => {
		if (orderId !== undefined) {
			setIsLoading(true);
			(async () => {
				try {
					if (isAuthenticated && !!accounts.length) {
						await instance
							.acquireTokenSilent(request)
							.then((response) => {
								if (response) {
									setToken(response.idToken);
								} else {
									console.log("no token");
								}
							})
							.catch((e) => {
								console.error(e);
								localStorage.clear();
								// redirect to login
								instance.loginRedirect(loginRequest);
							});
					} else {
						if (isLocalhost()) {
							// for local env
							console.log("Not authenticated on local host");
							setOrder(OrdersMockup.content[0]);
						} else {
							authenticate(instance);
						}
						setIsLoading(false);
					}
				} catch (e) {
					console.error(e);
				}
			})();
		}
	}, []);

	useEffect(() => {
		if (token && token !== undefined) {
			setIsLoading(true);
			getOrder(token, orderId);
		}
	}, [token]);

	useEffect(() => {
		if (order && order.payments) {
			const refundablePayment = findRefundablePayment(order);
			setPayment(refundablePayment);
		}
	}, [order]);

	useEffect(() => {
		// check that the payment is not empty or 0
		if (payment === null || !payment.amount) {
			setIsNotFound(true);
		} else {
			setIsNotFound(false);
		}
	}, [payment]);

	const handleRefundRadioSelection = () => {
		setIsCustomRefund(!isCustomRefund);
		// when switch between options, reset custom amount
		setCustomAmount(0);
		setIsValidCustomAmount(true);
		setExceedsMaxRefund(false);
		if (inputCustomRefund.current) {
			inputCustomRefund.current.value = "";
		}
	};

	const handleCustomRefundChange = (e) => {
		let formattedValue = e.target.value;
		// format value and exclude non-numeric characters except dot and coma
		formattedValue = formattedValue.replace(/[^0-9.]/g, "");
		e.target.value = formattedValue;
		setCustomAmount(formattedValue);
		// validate custom amount
		setExceedsMaxRefund(formattedValue > payment.amount);
		if (formattedValue > payment.amount || formattedValue <= 0) {
			setIsValidCustomAmount(false);
		} else {
			setIsValidCustomAmount(true);
		}
	};

	const clearRequestState = () => {
		setIsSuccess(false);
		setIsRequestLoading(false);
		setShowNotification(false);
		setIsFormValid(false);
	};

	const handleSubmitRefund = () => {
		// validate custom refund amount
		if (isCustomRefund) {
			if (customAmount <= 0 || customAmount > payment.amount) {
				setIsValidCustomAmount(false);
			} else {
				setIsValidCustomAmount(true);
			}
		} else {
			// when items are selected, the sum should be less than the max refund amount
			if (itemsAmount <= 0) {
				setIsValidItemsAmount(false);
			}
		}
		// valide refund reason
		setIsValidRefundReason(refundReason);
		// check if the form is valid
		const isValidRequest = validateRefundRequest(
			isCustomRefund,
			customAmount,
			payment.amount,
			itemsAmount,
			refundReason,
			inputRefundConfirmed.current.checked
		);
		if (isValidRequest) {
			const requestAmount = isCustomRefund ? customAmount : itemsAmount;
			setRefundAmount(requestAmount);
			setIsFormValid(true);
			setIsRefundTriggered(true);
			// build request body
			const requestBody = buildRefundRequestBody(
				requestAmount,
				payment.amount,
				payment.currency,
				refundReason,
				payment.paymentHash,
				payment.pspReference,
				accounts[0]
			);
			// send refund request
			try {
				if (requestBody) {
					setIsRequestLoading(true);
					sendRefundRequest(requestBody, token).then((response) => {
						if (
							response &&
							(response.status === 200 || response.status === 201)
						) {
							setIsSuccess(true);
							setErrorObj(null);
							setIsRequestLoading(false);
							setShowNotification(true);
							window.scrollTo(0, 0);
							// need to add auto-comments in case of selected items
							if (!isCustomRefund) {
								const commentBody = buildRefundCommentBody(refundReason, user);
								selectedItems.forEach((selectedItem) => {
									addComment(token, commentBody, selectedItem.id).then(
										(response) => {
											if (response.status !== 200 || response.status !== 201) {
												console.error("Comment not added", response);
											}
										}
									);
								});
							}
						} else {
							// when request fails
							console.error("Refund Error Response", response);
							if (response?.response?.status === 422) {
								setErrorObj(response?.response?.data);
							} else {
								setErrorObj(response);
							}
							setErrorStatus(
								response?.response?.status || response?.status || null
							);
							clearRequestState();
						}
					});
				} else {
					// when request body is not built
					clearRequestState();
					setErrorObj(CONSTANTS.DefaultError);
				}
			} catch (e) {
				// when request fails
				console.error(e);
				setErrorObj(e);
				clearRequestState();
			}
		} else {
			// when form is not valid
			setRefundAmount(0);
			clearRequestState();
			setIsRefundTriggered(false);
			console.error("Form is not valid");
		}
	};

	return (
		<PageContainer>
			<AccessGuard allowedAzureGroups={["REFUNDUSER"]} showMessage={true}>
				<>
					{!isRefundTriggered ? (
						<div className="RefundPage">
							<Heading size={2}>Order {orderId} Refund Request</Heading>
							{/* Order Details from the order */}
							{!isLoading ? (
								order && !isNotFound ? (
									<>
										<RefundOrderDetails order={order} payment={payment} />
										{/* selection between order items table and custom refund */}
										<Fieldset extraClasses="SelectionSection">
											{/* first option to select items for refund */}
											<Radio
												label={
													<span className="RadioLabel">
														<b>Select Items for refund</b>
													</span>
												}
												id="refund-table"
												name="refund-option"
												value="refund-table"
												key="refund-table"
												small
												checked={!isCustomRefund}
												onChange={() => handleRefundRadioSelection()}
											/>
											{/* table with order items for refund */}
											<div
												className={
													"RefundTableOption " +
													(!isCustomRefund ? "" : "RefundTableOption--disabled")
												}
											>
												<RefundItemsTable
													orderItems={order.orderItems}
													maxRefundAmount={payment.amount}
													isDisabled={isCustomRefund}
													onSelectedItemsChange={(sum, selectedItems) => {
														setItemsAmount(sum);
														setSelectedItems(selectedItems);
														if (sum) {
															setIsValidItemsAmount(true);
														}
													}}
												/>
											</div>
											{/* second option for custom refund */}
											<div className="CustomRefundOption">
												<Grid>
													<GridCol size={6} md={3}>
														<Radio
															label={
																<span className="RadioLabel">
																	<b>Custom Refund</b>
																</span>
															}
															id="custom-refund"
															name="refund-option"
															value="custom-refund"
															key="custom-refund"
															small
															onChange={() => handleRefundRadioSelection()}
														/>
													</GridCol>

													{/* input for custom refund amount */}
													<GridCol size={6} md={3}>
														<Input
															type="text"
															innerRef={inputCustomRefund}
															inlineLabelRight={payment.currency}
															extraClasses="RefundForm__input"
															disabled={!isCustomRefund}
															aria-label="Custom Refund Amount"
															aria-describedby="refund-amount"
															valid={
																!exceedsMaxRefund && isValidCustomAmount
																	? null
																	: false
															}
															infoError={
																exceedsMaxRefund
																	? "Amount to Refund exceeds the Maximum Refundable Amount"
																	: isValidCustomAmount
																	? null
																	: "Please enter an amount to refund"
															}
															onChange={(e) => {
																handleCustomRefundChange(e);
															}}
														/>
													</GridCol>
												</Grid>
											</div>
										</Fieldset>

										{/* refund form */}
										<Grid extraClasses="RefundForm__row RefundForm__row--aligned">
											<GridCol size={6} md={3}>
												<Heading size={4}>Maximum Refundable Amount</Heading>
											</GridCol>
											<GridCol size={6} md={3} extraClasses="RefundAmount">
												<span>
													<b>
														<Price
															value={payment.amount || 0}
															currency={payment.currency}
														/>
													</b>
												</span>
											</GridCol>
										</Grid>
										{/* readonly amount to refund */}
										<Grid extraClasses="RefundForm__row RefundForm__row--aligned">
											<GridCol size={6} md={3}>
												<Heading size={4}>Amount to Refund</Heading>
											</GridCol>
											<GridCol size={6} md={3} extraClasses="RefundAmount">
												<span>
													<b>
														<Price
															value={
																isCustomRefund
																	? customAmount || 0
																	: itemsAmount || 0
															}
															currency={payment.currency}
														/>
													</b>
												</span>
											</GridCol>
										</Grid>
										<RefundAmountError
											isCustomRefund={isCustomRefund}
											isValidItemsAmount={isValidItemsAmount}
											isValidCustomAmount={isValidCustomAmount}
											maxRefundAmount={payment.amount}
											itemsAmount={itemsAmount}
											customAmount={customAmount}
										/>
										<Grid extraClasses="RefundForm__row RefundForm__row--aligned">
											<GridCol size={6} md={3}>
												<Heading size={4}>Refund Reason</Heading>
											</GridCol>
											<GridCol size={6} md={3}>
												<Select
													extraClasses="RefundForm__input"
													id="refund-reason"
													data-testid="refund-reason"
													placeholder="Please select"
													valid={isValidRefundReason ? null : false}
													onChange={({ target }) => {
														setRefundReason(target.value);
														if (target.value) {
															setIsValidRefundReason(true);
														}
													}}
												>
													{/* go through each RefundReasons */}
													{CONSTANTS.RefundReasons.map((reason, index) => (
														<SelectOption key={index} value={reason}>
															{reason}
														</SelectOption>
													))}
												</Select>
											</GridCol>
										</Grid>
										<Grid>
											<GridCol size={12} md={6}>
												<Checkbox
													extraClasses="RefundForm__checkbox"
													label="I confirm I want to request this refund"
													id="refund-confirmation"
													data-testid="refund-confirmation"
													value="confirmation"
													innerRef={inputRefundConfirmed}
													small
													onChange={(e) => {
														setButtonAppearance(
															e.target.checked ? "primary" : ""
														);
													}}
												/>
											</GridCol>
										</Grid>
										<Grid>
											<GridCol size={12} md={6}>
												<div className="RefundForm__submit">
													<Button
														data-testid="refund-submit"
														appearance={buttonAppearance}
														disabled={!buttonAppearance}
														onClick={() => {
															handleSubmitRefund();
														}}
													>
														<b>Submit Refund Request</b>
													</Button>
												</div>
											</GridCol>
										</Grid>
									</>
								) : (
									<NoResultsMessage />
								)
							) : (
								<div
									className="Loader"
									aria-live="polite"
									aria-busy={isLoading}
									data-testid="loader"
								>
									<Spinner />
								</div>
							)}
						</div>
					) : isRequestLoading ? (
						<div
							className="Loader"
							aria-live="polite"
							aria-busy={isRequestLoading}
							data-testid="loader"
						>
							<Spinner />
						</div>
					) : isSuccess ? (
						<>
							<RequestSuccess
								orderId={orderId}
								refundAmount={refundAmount}
								currency={payment.currency}
								refundReason={refundReason}
							/>
							{/* Notifications */}
							{showNotification && isSuccess ? (
								<RefundNotification isCustomRefund={isCustomRefund} />
							) : null}
						</>
					) : (
						<RequestError errorObj={errorObj} orderId={orderId} />
					)}
				</>
			</AccessGuard>
		</PageContainer>
	);
};

export default Refund;
